import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/54654bf8/website/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "mini-codebase-func"
    }}>{`@mini-code/base-func`}</h1>
    <p>{`@mini-code/base-func 是一个语义化辅助函数库，提供通用的数据处理函数。`}</p>
    <p><a parentName="p" {...{
        "href": "https://travis-ci.com/SANGET/basic-helper-js"
      }}><img alt="Build Status" src="https://travis-ci.com/SANGET/basic-helper-js.svg?branch=master" /></a>{`
`}<a parentName="p" {...{
        "href": "https://packagephobia.now.sh/result?p=basic-helper"
      }}><img alt="install size" src="https://packagephobia.now.sh/badge?p=basic-helper" /></a></p>
    <h2 {...{
      "id": "安装"
    }}>{`安装`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`yarn add @mini-code/base-func
`}</code></pre>
    <h2 {...{
      "id": "使用"
    }}>{`使用`}</h2>
    <h3 {...{
      "id": "调用不确定函数"
    }}>{`调用不确定函数`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Call } from '@mini-code/base-func';

let func;

Call(func, args1, args2);
`}</code></pre>
    <h2 {...{
      "id": "更多扩展"
    }}>{`更多扩展`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./docs/event.md"
        }}>{`订阅发布`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./docs/storage.md"
        }}>{`本地数据存储`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./docs/registe.md"
        }}>{`全局注册`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      